import React from "react"
import { Link } from "gatsby"
import { motion } from 'framer-motion'
import {StaticImage} from "gatsby-plugin-image"
import Layout from "../components/layout"
import Cta from "../components/cta"
import Seo from "../components/seo"
import TestimonialSection from "../components/testimonials-section"
import Hero from "../components/hero"
import ArrowRight from "../components/svg/arrowRight"

const StartupPage = () => (
    <Layout>
        <Seo 
            title="Web Application Development for Startups in Sydney & Canberra, Australia" 
            description="Launch Lab are one of the most experienced startup developers in Australia. We develop web applications and mobile apps for startup founders using AWS, React and Python."
            pathname="/startup-web-application-development/"
            serviceType="Startup web application development"
        />

        <Hero
            h1="Startup Developers in Sydney and Canberra"  
            h2="We're one of the most experienced startup developers in Australia having built more than 40 startups"
        /> 

        <section className="bg-purple with-pad-sm">
            <div className="container">
                <div className="row">
                    <motion.div 
                      className="below-hero"
                      initial={{ opacity: 0, x: -120 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      viewport={{ once: true }}
                      transition={{ ease: "easeOut", delay: 0.25, duration: 0.75 }}
                    >
                        <div className="below-hero-widget">
                            <span className="widget-heading">40+</span>
                            <p>We've developed more than 40 startup applications and MVPs.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">2015</span>
                            <p>We started Launch Lab in 2015 to help non-technical founders get started.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">Local</span>
                            <p>We're an onshore Australian startup development team.</p>
                        </div>
                    </motion.div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h3
                            >We help startups get started</h3>
                        </div>

                        <div className="col-67 long-text"
                        >
                            <p>
                                Launch Lab helps non-technical startup founders plan, build and ship their product.
                            </p>
                            <p>
                                We are local, and highly experienced, <strong>Australian startup developers</strong>. 
                            </p>
                            <p>
                                We assist founders with web design, product design and web application development using AWS, Python, and React.
                            </p>
                            <p>We have experience with marketplace startups, social networking startups, healthtech, HRtech, REtech, Fintech, pure Saas startups as well as experience in data science and established industries where we've completed work for large household name brands and Government.</p>
                            <p>We believe in mutually beneficial relationships. If your startup is a success it usually means more work for us in future so <strong>your success means the world to us</strong> ... plus it makes us feel good too.</p>
                            <div className="btn-row">
                                <Link to="/contact/" className="btn btn-lg btn-purple">
                                    <span className="flex flex-ac">
                                        Contact us
                                        <ArrowRight />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad">
            <div className="container-lg">
                <div className="row">
                <div className="work-jumbo-wrapper with-radius">
                  <motion.div 
                        initial={{ opacity: 0, y: 120 }}
                        whileInView={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: 0, scale: [0.9, 1] }}
                        viewport={{ once: true }}
                        transition={{ ease: "easeOut", delay: "0.25", duration: 0.5 }}
                    > 
                        <StaticImage 
                            src="../images/launch-lab-work-2024.png"
                            alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                            className="with-radius"
                        />
                    </motion.div>
                </div>
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-pink">
                    <span className="flex flex-ac">
                        View all work
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
                </div>
            </div>
        </section>

        <section className="section-pad">
          <div className="container">
            <div className="row">
              <h4
              >Startup services</h4>
              <p
              >
                  Whether you're at the back-of-a-napkin idea phase or already have a working prototype, we can assist with each step of your startups design and development. Here are some of the ways we help early stage founders:
              </p>
              <div className="services-wrapper top-row"
              >
                <div className="a-service">
                  <h4>
                    Web Design
                  </h4>
                  <p>We <Link to="/startup-web-designers/">design</Link> easy-to-use MVP products and websites, using Figma, and assist our startups from intitial sketches to wireframes, clickable prototypes and fully designed product.</p>
                </div>
                <div className="a-service">
                  <h4>
                    Application Development
                  </h4>
                  <p>We develop marketing websites and landing pages using <Link to="/gatsby-developer/">Gatsby.js</Link>, Astro or Webflow and develop web applications using AWS, <Link to="/django-developer-sydney-canberra/">Python</Link> &amp; <Link to="/react-developers-sydney-canberra/">React.js</Link>. We give startups the right foundations so that they can continue to develop the product and begin to scale when required.</p>
                </div>
                <div className="a-service">
                  <h4>
                    AWS Support
                  </h4>
                  <p>We'll look after all your <Link to="/aws-infrastructure/">hosting and infrastructure</Link> needs using Amazon Web Services. This includes initial setup as well as ongoing support if required.</p>
                </div>
              </div>
              <div className="services-wrapper top-row"
              >
                <div className="a-service">
                  <h4>
                    Startup Advisors
                  </h4>
                  <p>We have experience in marketing, <Link to="/seo-for-startups/">SEO</Link> and of course the technical requirements of a startup. We've also developed more than 40 startups and have hands on experience starting businesses so we're well positioned to advise on what to expect on your startup journey.</p>
                </div>
                <div className="a-service">
                  <h4>
                    Strategy
                  </h4>
                  <p>We'll help you determine which features to develop first as well as a strategy to validate your concept. We can also advise on your initial marketing strategy and help determine financially viable distribution channels.</p>
                </div>
                <div className="a-service">
                  <h4>
                    Pitch Preparation
                  </h4>
                  <p>If your startup has some traction and the necessary metrics to raise investment, we'll help you prepare and refine your investor pitch and advise on where to start your investment search.</p>
                </div>
              </div>
              <div className="services-wrapper"
              >
                <div className="a-service">
                    <h4>
                      Chatgpt / LLMs
                    </h4>
                    <p>We leverage large language models (LLMs) like Chatgpt and Mistral, via AWS Bedrock, to automate tasks within applications that we develop.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="container">
            <div className="row">
            <div className="section-line"></div>
            </div>
        </div>

        <div className="container">
            <div className="row">
                <div className="well bg-purple">
                    <h4>AWS &amp; Webflow for startups partner</h4>
                    <p>Launch Lab is a <Link to="/webflow-developer/">Webflow for Startups</Link> partner and an <Link to="/aws-infrastructure/">AWS Activate</Link> partner. Contact us to find out how you can benefit from these startup programs.</p>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row">
            <div className="section-line"></div>
            </div>
        </div>

        <section className="section-pad-sm">
            <div className="container">
                <div className="row">   
                    <h4 className="text-center">Recent startup projects</h4>
                    <motion.div 
                        className="porti-row mt-3"
                        initial={{ opacity: 0, y: 120 }}
                        whileInView={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: 0, scale: [0.9, 1] }}
                        viewport={{ once: true }}
                        transition={{ ease: "easeOut", delay: "0.25", duration: 0.5 }}
                    >
                        <Link to="/feezy-case-study/" className="porti-item">
                            <div className="porti-item-img">
                                <StaticImage 
                                    src="../images/feezy-website.png"
                                    alt="Feezy website home page"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h3>Feezy</h3>
                                <p>We designed and developed a 2-sided marketplace and a marketing website for this Australia-based startup.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Web App</div>
                                    <div className="work-pill startup">Startup</div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/enabli-case-study/" className="porti-item">
                            <div className="porti-item-img">
                                <StaticImage 
                                    src="../images/enabli-screens.png"
                                    alt="A collage of the Enabli website and application screens"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h4>Enabli</h4>
                                <p>We designed and developed HR Tech software and a marketing website for this Sydney-based startup.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Web App</div>
                                    <div className="work-pill startup">Startup</div>
                                </div>
                            </div>
                        </Link>

                    </motion.div>
                </div>
                
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-purple">
                    <span className="flex flex-ac">
                        View all projects
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
            </div>
        </section>

        <TestimonialSection />
        
        <Cta 
            text="Get a startup web design quote and / or a startup web development quote from a local onshore Australian tech team."
        />
    </Layout>
)

export default StartupPage